const {transliterate} = require('transliteration');

(() => {
    'use strict';

    angular
        .module('App.Hashtags')
        .factory('HashtagsService', ['$http', HashtagsService]);

    function HashtagsService($http) {

        return {
            getSocialPostsWithHashtag: getSocialPostsWithHashtag,
            getContentPostsWithHashtag: getContentPostsWithHashtag,
            getStampPositions: getStampPositions,
            getStamp: getStamp,
            POST_TYPES: POST_TYPES
        };
        
        function POST_TYPES() {
            return {
                Social: 'Social',
                Content: 'Content'
            }
        }       

        function getSocialPostsWithHashtag(hashtag, offset = 0, limit = 20) {
            return $http.post('/Social/GetPostsWithHashtag', {
                offset: offset,
                limit: limit,
                hashtag: hashtag
            }).then(function (response) {
                return response.data;
            });
        }

        function getContentPostsWithHashtag(hashtag, offset = 0, limit = 20) {
            return $http.post('/ContentModule/GetPostsWithHashtag', {
                offset: offset,
                limit: limit,
                hashtag: hashtag
            }).then(function (response) {
                return response.data;
            });
        }

        function getStampPositions() {
            return {
                Image: 'top-of-image',
                Post: 'top-of-post',
                Floating: 'floating',
                Falling: 'falling'
            }
        }

        function getStamp(hashtags, module) {
            if (!hashtags) return;

            const stamps = hashtags.filter(hashtag =>  {
                return hashtag.StampMedia &&
                       getStampModuleType(hashtag).includes(module)
            });

            if (stamps?.length) {
                const stampMedia = stamps[0].StampMedia;
                const stampName = stamps[0].Name;
                let stampCssClassName = '';

                if (stampName?.length) {
                    try {
                        stampCssClassName = 'hashtag-' + transliterate(stampName).toLowerCase();
                    } catch (err) {
                        stampCssClassName = 'hashtag-' + stampName.toLowerCase();
                        console.error(err);
                    }
                }

                return {
                    Url: stampMedia.ImageFormats?.W250,
                    StampMedia: stampMedia,
                    Name: stampName,
                    CssClassName: stampCssClassName,
                    Position: mapStampPosition(stamps[0])
                }
            }
        }
        
        function mapStampPosition(hashtag) {
            const positions = getStampPositions();
        
            switch(hashtag.StampPlacementType) {
                case 1:
                    return positions.Post;
                case 2:
                    return positions.Image;
                case 3:
                    return positions.Floating;
                case 4:
                    return positions.Falling;
                default:
                    return 'Unknown';
            }
        }

        function getStampModuleType(hashtag) {
            switch (hashtag.StampModuleType) {
                case 1:
                    return 'Social';
                case 2:
                    return 'Content';
                case 3:
                    return 'SocialContent';
                default:
                    return 'Unknown';
            }
        } 
    }
})();
