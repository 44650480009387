(() => {
    'use strict';

    angular
        .module('App.Hashtags')
        .component('hashtagsOverview', {
            template: require('./HashtagsOverviewComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$state', 'Page', HashtagsOverviewController],
            bindings: {
                socialData: '<',
                contentData: '<',
                hashtag: '<',
                onDataChange: '&'
            }
        });

        function HashtagsOverviewController($state, Page) {
            const ctrl = this;

            ctrl.$onInit = onInit;
            ctrl.$onChanges = onChanges;
            ctrl.changeTab = changeTab;
                        
            ctrl.views = {
                Content: 'Content',
                Social: 'Social'
            }

            function onInit() {
                if (isAnyTabEmpty()) {
                    setSingleTabView();
                } else {
                    setActiveViewByParams();
                }                
            }

            function onChanges(changes) {
                if (!changes.socialData?.isFirstChange() ||
                    !changes.contentData?.isFirstChange() ) {
                        onInit();
                    }
            }

            function isAnyTabEmpty() {
                return !ctrl.socialData.Posts.length || !ctrl.contentData.Posts.length;
            }

            function setActiveViewByParams() {
                ctrl.activeView = $state.params?.extra === ctrl.views.Content 
                    ? ctrl.views.Content 
                    : ctrl.views.Social;
            }

            function setSingleTabView() {
                const defaultView = !ctrl.contentData.Posts.length 
                    ? ctrl.views.Social 
                    : ctrl.views.Content;
            
                updateUrl(defaultView);
                ctrl.activeView = defaultView;
            }

            function changeTab(clickedTab) {
                ctrl.activeView = clickedTab;
                updateUrl(clickedTab);
            }

            function updateUrl(tab) {
                Page.stateGo(
                    $state.current?.name, 
                    $state.params?.token, 
                    tab, 
                    {
                        contentPostsData: ctrl.contentData,
                        socialPostsData: ctrl.socialData
                    }, 
                    false
                );
            }
        }
})();