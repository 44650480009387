(() => {
    'use strict';

    angular.module('App.Training')
    .component('scormPage', {
        template: require('./ScormPageComponent.html'),
        controllerAs: 'ctrl',
        controller: ['$http', '$sce', '$timeout', 'Page', ScormPage],
        bindings: {
            moduleToken: '<',
            courseToken: '<',
            learningPathToken: '<'
        }
    });

    function ScormPage($http, $sce, $timeout, Page) {
        var ctrl = this;

        ctrl.$onInit = onInit;

        function onInit() {
            ctrl.loading = true;

            const settings = {
                autocommit: true,
                autocommitSeconds: 60,
                dataCommitFormat: 'json',
                commitRequestDataType: 'application/json;charset=UTF-8',
                asyncCommit: true,
                autoProgress: false,
                logLevel: 1,
                // mastery_override: false,
                lmsCommitUrl: "TrainingV2/Scorm/LmsCommit",
                requestHandler: requestHandler,
                responseHandler: responseHandler
            };

            $http.get('/TrainingV2/Scorm/GetData/?token=' + ctrl.moduleToken)
            .then(resp => {
                ctrl.packageVersion = resp.data.PackageVersion;
                if (resp.data.PackageVersion === 1)
                {
                    // SCORM 2004
                    window.API_1484_11 = new Scorm2004API(settings);

                    let dataFromLms = {
                        cmi: {
                            entry: resp.data.Entry,
                            learner_id: resp.data.LearnerId,
                            learner_name: resp.data.LearnerName,
                            location: resp.data.Location,
                            suspend_data: resp.data.SuspendData,
                            interactions: resp.data.Interactions
                        }
                    };
                    window.API_1484_11.loadFromJSON(dataFromLms, '');
                } else {
                    // SCORM 1.2
                    window.API = new Scorm12API(settings);

                    let dataFromLms = {
                        cmi: {
                            core: {
                                entry: resp.data.Entry,
                                student_id: resp.data.LearnerId,
                                student_name: resp.data.LearnerName,
                                lesson_location: resp.data.Location,
                                suspend_data: resp.data.SuspendData,
                                interactions: resp.data.Interactions
                            }
                        }
                    };
                    window.API.loadFromJSON(dataFromLms, '');
                }

                getIframe();
            });
        }

        function getIframe () {
            $http.get('/TrainingV2/Scorm/GetScormIframe/?token=' + ctrl.moduleToken)
                .then(resp => {
                    Page.showMenuButton(!resp.data.HideMenuButton);
                    Page.setTitle(resp.data.Title);
                    var url = window.location.origin + resp.data.Url;
    
                    // bind data
                    ctrl.iframeUrl = $sce.trustAsResourceUrl(url);
                    ctrl.url = url;
                    ctrl.background = resp.data.BackgroundImage;
                    ctrl.title = resp.data.Title;
                    ctrl.urlPreview = resp.data.UrlPreview;
                    if (resp.data.Orientation !== undefined) {
                        ctrl.Orientation = resp.data.Orientation;
                    }
    
                    $timeout(() => {
                        ctrl.loading = false;
                    });
    
                    if (resp.data.LockVerticalScroll) {
                        angular.element('body').addClass('lock-vertical-scrolling')
                    }
                });
        }

        function requestHandler (commitObject) {
            if (commitObject != undefined) {
                commitObject.courseToken = ctrl.courseToken;
                commitObject.courseModuleToken = ctrl.moduleToken;
                commitObject.learningPathToken = ctrl.learningPathToken;
                return commitObject;
            }
        }

        function responseHandler (response) {
            let result = response.ok;
            let errorCode = 0;

            if (!result) {
                errorCode = response.status;
            }

            return {
                result: result,
                errorCode: errorCode
            };
        };
}
})();