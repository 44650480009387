(function () {
    'use strict';

    angular.module('App.Hashtags')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('hashtagsOverview', {
                    parent: 'base',
                    url: '/hashtagsOverview/:token/:extra',
                    template: `<hashtags-overview 
                                    class="view-page" 
                                    hashtag="ctrl.hashtag" 
                                    social-data="ctrl.socialData" 
                                    content-data="ctrl.contentData"
                                    on-data-change="ctrl.refreshHashtags()"
                               </hashtags-overview>`,
                    params: {
                        backState: null,
                        data: undefined
                    },
                    resolve: {
                        searchResultsData: ['$stateParams', '$q', 'HashtagsService', 'Page',
                            function ($stateParams, $q, HashtagsService, Page) {
                                const fromBackNavigation = $stateParams?.token === Page.getBackHistoryState()?.token;
                                if ($stateParams?.data?.contentPostsData && !fromBackNavigation) {
                                    return $stateParams.data;
                                }
                                
                                return $q.all({
                                    socialPostsData: HashtagsService.getSocialPostsWithHashtag($stateParams.token),
                                    contentPostsData: HashtagsService.getContentPostsWithHashtag($stateParams.token)
                                })  
                            }]
                    },
                    controller: 'HashtagsOverviewController as ctrl'
                });
        }])
        .controller('HashtagsOverviewController', 
            ['$stateParams', '$translate', '$q', 'HashtagsService', 'Page', 'searchResultsData', HashtagsOverviewController]
        );

    function HashtagsOverviewController($stateParams, $translate, $q, HashtagsService, Page, searchResultsData) {
        const ctrl = this;
        ctrl.refreshHashtags = refreshHashtags;

        Page.showBackButton();
        Page.setTitle('#' + $stateParams.token);

        $translate('HASHTAGGING.POSTS.LABEL').then(function (translation) {
            Page.setSubTitle(`${ctrl.postsCount || 0} ${translation}`)
        });

        const settings = {
            Settings: {...ctrl.settings, IsSharedView: false, CardedContent: true},
            HashtagName: $stateParams.token
        }

        ctrl.socialData = {
            ...searchResultsData.socialPostsData, 
            ...settings
        };

        ctrl.contentData = {
            ...searchResultsData.contentPostsData,
            ...settings
        }

        ctrl.postsCount = ctrl.socialData.Hashtag.PostsCount + ctrl.contentData.Hashtag.PostsCount;
        ctrl.hashtag = ctrl.socialData.Hashtag || {};

        function refreshHashtags() {
            $q.all({
                socialPostsData: HashtagsService.getSocialPostsWithHashtag($stateParams.token),
                contentPostsData: HashtagsService.getContentPostsWithHashtag($stateParams.token)
            }).then(updatedData => {
                ctrl.socialData = { ...updatedData.socialPostsData, Settings: ctrl.socialData.Settings };
                ctrl.contentData = { ...updatedData.contentPostsData, Settings: ctrl.contentData.Settings };
                ctrl.postsCount = (ctrl.socialData.Hashtag?.PostsCount || 0) + (ctrl.contentData.Hashtag?.PostsCount || 0);
                ctrl.hashtag = ctrl.socialData.Hashtag || {};

                $translate('HASHTAGGING.POSTS.LABEL').then(function (translation) {
                    Page.setSubTitle(`${ctrl.postsCount || 0} ${translation}`)
                });
            }).catch(error => {
                console.error('Error refreshing hashtags:', error);
            });
        }
    }
})();