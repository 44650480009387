(() => {
    'use strict';

    angular
        .module(
            'App.Hashtags',
            ['App.Common', 'App.Basics']
        );

    require([        
        './HashtagsService.js',
        
        './HashtagsOverview/HashtagsOverviewComponent',
        './HashtagsOverview/HashtagsOverviewController',

        './HastagSuggestions/HastagSuggestionsComponent'
    ])
})();