(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonTestimonials', {
            controllerAs: 'ctrl',
            template: require('./LessonTestimonialsComponent.html'),
            controller: ['$scope', '$element', 'LessonPageService', 'ToastFactory', LessonTestimonialsController],
            bindings: {
                onHideTestimonials: '<',
                commentRequiredScore: '<',
                courseToken: '<',
            }
        })

    function LessonTestimonialsController($scope, $element, LessonPageService, ToastFactory) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.submitTestimonials = submitTestimonials;
        ctrl.proceed = proceed;
        ctrl.onClose = onClose;
        
        function init() {
            ctrl.commentRequiredScore = ctrl.commentRequiredScore || 0;
            ctrl.testimonial = {
                CourseToken: ctrl.courseToken
            };
        }

        function submitTestimonials() {
            if (ctrl.isLoading) {
                return false
            }
            
            if (!ctrl.testimonial.comment?.length && !ctrl.testimonial.rating) {
                ToastFactory.error('TRAINING.LESSON.TESTIMONIAL.RATING_ERROR');
            } else if (ctrl.testimonial.rating < ctrl.commentRequiredScore && !ctrl.testimonial.comment) {
                ToastFactory.error('TRAINING.LESSON.TESTIMONIAL.EMPTY');
            } else {
                ctrl.isLoading = true;
                LessonPageService
                    .submitTestimonial(ctrl.testimonial)
                    .then(({Score, Success}) => {
                        ctrl.testimonial.submitted = Success;
                        ctrl.testimonial.rating = Score;
                    })
                    .finally(() => {
                        ctrl.isLoading = false;
                    });
            }
        }
        
        function proceed() {
            ctrl.closeAction && ctrl.closeAction();
            ctrl.onHideTestimonials && ctrl.onHideTestimonials();
            onClose();
        }

        function onClose() {
            $scope.$destroy();
            $element.remove()
        }
    }
})();