(() => {
    'use strict';

    angular.module('App.Training')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('trainingScormCloudCourse', {
                    parent: 'base',
                    url: '/training/:token/scormcloud/:extra',
                    template: require('./ScormCloudCoursePage.html'),
                    controller: 'ScormCloudCoursePageController as ctrl',
                    params: {
                        backState: null,
                        extra: null,
                        data: {}
                    }
                });
        }])
        .controller('ScormCloudCoursePageController', ['$http', '$scope', '$sce', '$stateParams', 'events', 'Page',
            '$timeout', 'ActionPopupService', 'TrainingService',
            function ($http, $scope, $sce, $stateParams, events, Page, $timeout, ActionPopupService, TrainingService) {
                const ctrl = this,
                    courseToken = $stateParams.data.courseToken,
                    isVoluntary = $stateParams.data.isVoluntary,
                    courseModuleId = $stateParams.extra;

                ctrl.loading = true;

                Page.showBackButtonFunction(() => {
                    if (!ctrl.backInProgress) {
                        ctrl.backInProgress = true;
                        $http.post('/Training/CourseModule/GetScormParticipation', {
                            courseModuleId: courseModuleId,
                            courseToken: courseToken
                        }).then(resp => {
                            if (resp.data.NewUserCourseBadges.length || resp.data.GiveTestimonial) {
                                resp.data.CourseToken = courseToken;
                                ActionPopupService
                                    .create(`<badge-testimonials-popup data="data" on-submit="onSubmit"
                                         ></badge-testimonials-popup>`, {
                                        data: resp.data,
                                        onSubmit: () => {
                                            onSubmit(resp);
                                        }
                                    })
                            } else {
                                onSubmit(resp);
                            }
                            ctrl.backInProgress = false;
                        })
                    }
                });

                function onSubmit(resp) {
                    if (resp.data.PhasesOverviewEnabled) {
                        if (resp.data.PhaseStateId === 3) {
                            goToRootTraining();
                        } else if (resp.data.CourseStateId === 3) {
                            if (isVoluntary) {
                                goToVoluntary()
                            } else {
                                Page.stateGoBack('trainingPhase', resp.data.TrainingPhaseToken);
                            }
                        } else if ([1, 2, 3].some(id => id === resp.data.ModuleStateId)) {
                            moduleCompleted();
                        }
                    } else {
                        if (resp.data.CourseStateId === 3) {
                            goToRootTraining();
                        } else if ([1, 2, 3].some(id => id === resp.data.ModuleStateId)) {
                            moduleCompleted()
                        }
                    }

                    TrainingService.showScormUpdateAlert();


                    function moduleCompleted() {
                        if (isVoluntary) {
                            goToVoluntary()
                        } else {
                            goToCourse();
                        }
                    }

                    function goToRootTraining() {
                        Page.stateGoBack('training', resp.data.AccountModuleToken);
                    }

                    function goToCourse() {
                        Page.stateGoBack('trainingCourse', courseToken);
                    }

                    function goToVoluntary() {
                        Page.stateGoBack('training', resp.data.AccountModuleToken, 'voluntary');
                    }
                }

                $http.get('/Training/CourseModule/GetScormIframe/?courseModuleId=' + $stateParams.extra)
                    .then(resp => {
                        Page.showMenuButton(!resp.data.HideMenuButton);
                        Page.setTitle(resp.data.Title);

                        // bind data
                        ctrl.iframeUrl = $sce.trustAsResourceUrl(resp.data.Url);
                        ctrl.url = resp.data.Url;
                        ctrl.background = resp.data.BackgroundImage;
                        ctrl.title = resp.data.Title;
                        ctrl.urlPreview = resp.data.UrlPreview;
                        if (resp.data.Orientation !== undefined) {
                            ctrl.Orientation = resp.data.Orientation;
                        }

                        $timeout(() => {
                            ctrl.loading = false;
                        });

                        if (resp.data.LockVerticalScroll) {
                            angular.element('body').addClass('lock-vertical-scrolling')
                        }
                    });

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }]);
})();