(() => {
    'use strict';

    const template =
        `<training-module-page
            class="view-page"
            module="ctrl.module"
            lessons="ctrl.lessons"
            course-token="ctrl.courseToken"
            account-module-token="ctrl.accountModuleToken" 
            performance-ranges="ctrl.performanceRanges"
            learning-path-token="ctrl.learningPathToken"    
         ></training-module-page>`

    angular
        .module('App.Training')
        .config(['$stateProvider', $stateProvider => {
            $stateProvider
                .state('trainingCourseModuleMandatory', {
                    parent: 'trainingSettings',
                    url: '/courseModuleMandatory/:token/:extra/:learningPathToken',
                    resolve: {
                        trainingData: ['$stateParams', 'trainingSettings', 'TrainingService', function ($stateParams, trainingSettings, TrainingService) {
                            return TrainingService.getCourseModule({
                                courseModuleToken: $stateParams.token,
                                courseToken: $stateParams.extra,
                                learningPathToken: $stateParams.learningPathToken
                            });
                        }]
                    },
                    params: {
                        backState: null,
                        data: {}
                    },
                    template: template,
                    controller: 'TrainingCourseModuleController as ctrl'
                })
                .state('trainingCourseModuleVoluntary', {
                    parent: 'trainingSettings',
                    url: '/courseModuleVoluntary/:token',
                    params: {
                        isVoluntary: true,
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        trainingData: ['$stateParams', 'trainingSettings', 'TrainingService', function ($stateParams, trainingSettings, TrainingService) {
                            return TrainingService.getCourseModule({
                                courseModuleToken: $stateParams.token,
                            });
                        }]
                    },
                    template: template,
                    controller: 'TrainingCourseModuleController as ctrl'
                })
        }])
        .controller('TrainingCourseModuleController', ['$scope', '$state', '$stateParams', 'Page', 'trainingData', 'events',
            function ($scope, $state, $stateParams, Page, trainingData, events) {
                const ctrl = this;

                Page.setTitle(trainingData.PageTitle);

                ctrl.accountModuleToken = trainingData.AccountModuleToken;
                ctrl.lessons = trainingData.Lessons;
                ctrl.module = trainingData.Module;
                ctrl.courseToken = trainingData.CourseToken;
                ctrl.performanceRanges = trainingData.PerformanceRanges;
                ctrl.learningPathToken = $stateParams.learningPathToken;

                Page.showBackButton();

                $scope.$on(events.DEPARTMENT_CHANGED, () => {
                    Page.stateGoBack('training', trainingData.AccountModuleToken);
                });


            }
        ]);
})();