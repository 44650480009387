(() => {
    'use strict';

    angular.module('App.Training')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('trainingScorm', {
                    parent: 'base',
                    url: '/training/:token/scorm',
                    template: '<scorm-page module-token="ctrl.courseModuleToken" course-token="ctrl.courseToken" learning-path-token="ctrl.learningPathToken"></scorm-page>',
                    controller: 'ScormPageController as ctrl',
                    params: {
                        backState: null,
                        extra: null,
                        data: {}
                    }
                });
        }])
        .controller('ScormPageController', ['$scope', '$stateParams', 'events', 'Page',
            function ($scope, $stateParams, events, Page) {
                const ctrl = this;
                ctrl.courseToken = $stateParams.data.courseToken,
                ctrl.courseModuleToken = $stateParams.token,
                ctrl.learningPathToken = $stateParams.data.learningPathToken;

                Page.showBackButton();

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }])
})();