(() => {
    'use strict';

    angular
        .module('App.Basics')
        .factory('CopyLinkService', ['$http','$timeout', 'ToastFactory', 'BasicHelper', 'ActionPopupService', '$q', CopyLinkService]);

    function CopyLinkService($http, $timeout, ToastFactory, BasicHelper, ActionPopupService, $q) {
        return {
            copy: copy,
            generate: generate
        }

        function copy(url, el) {
            generate(url).then(link => {
                if (BasicHelper.isOldFF()) {
                    createCopyPopup(link);
                } else {
                    el.val(link);
                    $timeout(() => {
                        BasicHelper.CopyClipbord(el);
                        ToastFactory.success('COPIED_TO_CLIPBOARD');    
                        //Hide keyboard on mobile device
                        BasicHelper.clearTextSelection(el);
                    });
                }
            }).catch(() => {
                ToastFactory.error('COPIED_TO_CLIPBOARD_ERROR');
            })
        }

        function generate(url){
            var defer = $q.defer();
            $http.post(url).then(resp => {
                defer.resolve(resp.data.Link);
            }).catch(() => {
                defer.reject(null);
            });

            return defer.promise;
        }

        function createCopyPopup(link) {
            return ActionPopupService.create(getTemplate(), {
                copy: copyFromPopup,
                link: link
            });

            function getTemplate() {
                return `<action-popup class="copy-link-popup" close="close">
                    <style>
                        .rl-input.copy-link-input {
                            padding-right: 50px;
                            width: 300px;
                        }
                        .copy-link-btn {
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                        .copy-link-btn button {
                            min-width: auto;
                        }
                    </style> 
                    <input type="text" ng-model="link" class="rl-input copy-link-input">
                    <rl-button type="transparent" icon="copy" (click)="copy(close)" class="copy-link-btn"></rl-button>
                </action-popup>`
            }

            function copyFromPopup(close) {
                BasicHelper.CopyClipbord(angular.element('.copy-link-input'));
                ToastFactory.success('COPIED_TO_CLIPBOARD');
                close && close();
            }
        }
    }
})();