(() => {
    'use strict';

    angular
        .module('App.Hashtags')
        .component('hashtagSuggestions', {
            template: require('./HastagSuggestionsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$scope', '$element', 'MentionService', HashtagSuggestionsController],
            bindings: {
                hashtagsSuggestions: '=?',
                onTagSelected: '<',
                text: '<',
                accountModuleToken: '<',
                onBlur: '&'
            }
        })

    function HashtagSuggestionsController($timeout, $scope, $element, MentionService) {
        const ctrl = this;
        let removeHashtagWatch;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.tagClick = tagClick;

        function init() {
            ctrl.suggestionsLoading = true;
            if (!ctrl.hashtagsSuggestions?.length) {
                MentionService.getHashTags(ctrl.accountModuleToken)
                    .then(hashtags => {
                        ctrl.hashtagsSuggestions = hashtags;
                        watchTag();
                    });
            } else {
                watchTag();
            }

            setBlurListener();
        }

        function setBlurListener() {
            $element.on('click', preventFromBlur);
            ctrl.inputElement = document.querySelector('#search-input');
            ctrl.inputElement?.addEventListener('blur', handleBlurEvent);
        }

        function handleBlurEvent() {
            $timeout(() => {
                if (!ctrl.suggestionClicked) ctrl.onBlur();
            }, 200)
        }

        function preventFromBlur(event) {
            ctrl.suggestionClicked = true;
            event.stopPropagation();
            event.preventDefault();
        }

        function watchTag() {
            $timeout(() => {
                ctrl.suggestionsLoading = false;
                removeHashtagWatch = $scope.$watch('ctrl.text', (text) => {
                    ctrl.hashTags = MentionService.sortHashtagsSuggestions(
                        ctrl.hashtagsSuggestions,
                        text
                    );
                })
            })
        }

        function tagClick(name) {
            ctrl.onTagSelected && ctrl.onTagSelected(name)
        }

        function destroy() {
            $element.off('click', preventFromBlur)
            ctrl.inputElement?.removeEventListener('blur', handleBlurEvent);
            removeHashtagWatch && removeHashtagWatch();
        }
    }
})();