(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('overviewTable', {
            template: require('./OverviewTableComponent.html'),
            controllerAs: 'ctrl',
            controller: ['TrainingManagerTabService', 'ToastFactory', '$timeout', OverviewTableController],
            bindings: {
                courses: '<',
                modules: '<',
                lessons: '<',
                userId: '<',
                onRelaunch: '<',
                settings: '<',
            }
        });

    function OverviewTableController(TrainingManagerTabService, ToastFactory, $timeout) {
        var ctrl = this,
            COLUMN = {
                NAME: {
                    title: 'TRAINING.COURSE.TITLE',
                    key: 'Name'
                },
                COMPLETION: {
                    title: 'TRAINING.COMPLETION_RATE',
                    key: 'CompletionRate'
                },
                PERFORMANCE: {
                    title: 'TRAINING.PERFORMANCE_SCORE',
                    key: 'PerformanceScore'
                }
            };

        ctrl.COLUMN = COLUMN;
        ctrl.columns = [COLUMN.NAME, COLUMN.COMPLETION, COLUMN.PERFORMANCE];
        ctrl.treeState = {
            course: [],
            module: []
        };

        ctrl.setPerformanceClass = TrainingManagerTabService.setPerformanceClass;

        ctrl.$onInit = function () {
            _.each(ctrl.courses, function (course) {
                course.modules = _.filter(ctrl.modules, {'CourseId': course.CourseId});

                _.each(course.modules, function (module) {
                    module.lessons = _.filter(ctrl.lessons, {'CourseModuleId': module.CourseModuleId})
                })
            })
        };

        ctrl.toggle = function (type, id) {
            ctrl.treeState[type][id] = !ctrl.treeState[type][id];
        };

        ctrl.sort = function (column) {
            ctrl.isReverse = (ctrl.selectedProperty === column.key) ? !ctrl.isReverse : false;
            ctrl.selectedProperty = column.key;
        }

        ctrl.relaunch = function (event, type, id, name) {
            TrainingManagerTabService.askForRelaunch(type, name, ctrl.settings.ScormEnabled).then(() => {
                TrainingManagerTabService
                    .relaunch(type, id, ctrl.userId)
                    .then(resp => {
                        ctrl.onRelaunch && ctrl.onRelaunch(resp.data);
                        ToastFactory.successTranslatedReplace('TRAINING.RELAUNCH.SUCCESS', '{name}', name);

                        // re-init controller to build new tree
                        $timeout(() => {
                            ctrl.$onInit();
                        })
                    })
                    .catch(resp => {
                        console.log(resp);
                    })
            }, function () {

            })

            // disabling toggle when click on redo
            event.stopPropagation();
            return false;
        }
    }
})();
