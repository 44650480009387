import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

type ResponsiveState =
    | 'mobile'
    | 'bigTablet.portrait'
    | 'smallTablet.portrait'
    | 'smallTablet.landscape'
    | 'bigTablet.landscape'
    | 'desktop';

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService implements OnDestroy {
    private readonly BREAKPOINTS = {
        MOBILE: 'screen and (max-width: 767px)',
        SMALL_TABLET_PORTRAIT: 'screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)',
        SMALL_TABLET_LANDSCAPE: 'screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)',
        BIG_TABLET_PORTRAIT: 'screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (min-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5)',
        BIG_TABLET_LANDSCAPE: 'screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)',
        DESKTOP: 'screen and (min-width: 1025px)'
    } as const;

    private readonly mediaState$ = new BehaviorSubject<ResponsiveState>(this.getState());
    private readonly resizeSubscription: Subscription;

    constructor() {
        this.resizeSubscription = fromEvent(window, 'resize')
            .pipe(
                debounceTime(250),
                map(() => this.getState()),
                distinctUntilChanged()
            )
            .subscribe(state => {
                if (this.mediaState$.value !== state) {
                    this.mediaState$.next(state);
                    this.dispatchCustomEvent(state);
                }
            });
    }

    public getState(): ResponsiveState {
        if (this.isMobile()) return 'mobile';
        if (this.isSmallTabletPortrait()) return 'smallTablet.portrait';
        if (this.isSmallTabletLandscape()) return 'smallTablet.landscape';
        if (this.isBigTabletPortrait()) return 'bigTablet.portrait';
        if (this.isBigTabletLandscape()) return 'bigTablet.landscape';
        if (this.isDesktop()) return 'desktop';
        return 'desktop';
    }

    public isMobile(): boolean {
        return this.matchMedia(this.BREAKPOINTS.MOBILE);
    }

    public isSmallTabletPortrait(): boolean {
        return this.matchMedia(this.BREAKPOINTS.SMALL_TABLET_PORTRAIT);
    }

    public isSmallTabletLandscape(): boolean {
        return this.matchMedia(this.BREAKPOINTS.SMALL_TABLET_LANDSCAPE);
    }

    public isBigTabletPortrait(): boolean {
        return this.matchMedia(this.BREAKPOINTS.BIG_TABLET_PORTRAIT);
    }

    public isBigTabletLandscape(): boolean {
        return this.matchMedia(this.BREAKPOINTS.BIG_TABLET_LANDSCAPE);
    }

    public isDesktop(): boolean {
        return this.matchMedia(this.BREAKPOINTS.DESKTOP);
    }

    public state$(): Observable<ResponsiveState> {
        return this.mediaState$.asObservable();
    }

    private matchMedia(query: string): boolean {
        return window.matchMedia(query).matches;
    }

    private dispatchCustomEvent(state: ResponsiveState): void {
        window.dispatchEvent(new CustomEvent('responsiveService:resize', {detail: state}));
    }

    // Legacy support for older components
    public watch(callback: (state: ResponsiveState, ev: CustomEvent) => void) {
        const listener = (ev: Event) => callback((ev as CustomEvent).detail, ev as CustomEvent);
        window.addEventListener('responsiveService:resize', listener);

        return () => {
            window.removeEventListener('responsiveService:resize', listener)
        }
    }

    ngOnDestroy(): void {
        this.mediaState$.complete();
        this.resizeSubscription.unsubscribe();
    }
}